import { render, staticRenderFns } from "./riesgosModificable.vue?vue&type=template&id=47fb37ef&scoped=true&"
import script from "./riesgosModificable.vue?vue&type=script&lang=js&"
export * from "./riesgosModificable.vue?vue&type=script&lang=js&"
import style0 from "./riesgosModificable.vue?vue&type=style&index=0&id=47fb37ef&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47fb37ef",
  null
  
)

export default component.exports